@import '~@ui/styles/tools';

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.rectangles {
    position: absolute;
    max-width: 695px;
    width: 100%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    justify-content: center;
    row-gap: 12px;
}

.rectangle {
    height: 85px;
    max-width: 695px;
    border-radius: 14.4px;
    background-color: rgb(31, 122, 102);
    opacity: 0.1;

    &.rectangle--highlighted {
        width: 100%;
        height: 95px;
        border-radius: 16px;
        opacity: 1;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 16px;
    }

    &:not(.rectangle--highlighted) {
        margin: 0 25px;
    }
}

.manager-info {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.manager-name {
    color: #fff;
    font-size: 18.707px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.manager-description {
    color: #fff;
    font-size: 18.707px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: 0.401px;
}

.manager-chevron {
    margin-left: auto;
}

@include small-breakpoint {
    .rectangles {
        right: -70px;
    }

    .rectangle {
        &:first-of-type,
        &:last-of-type {
            display: none;
        }
    }
}

@include media-tablet {
    .rectangles {
        right: 0;
        left: 34px;
        width: unset;
        max-width: unset;
    }

    .rectangle {
        height: 36px;
        border-radius: 12px;

        &:first-of-type {
            display: none;
        }

        &:last-of-type {
            display: none;
        }

        &.rectangle--highlighted {
            width: unset;
            max-width: unset;
            height: 68px;

            padding: 12px;

            outline: 3px solid rgba(31, 122, 102, 0.1);
            outline-offset: 0;
            .image {
                margin-left: 0;

                img {
                    height: 44px !important;
                    width: 44px !important;
                }
            }
        }
    }

    .manager-name {
        font-size: 14px;
    }

    .manager-description {
        font-size: 14px;
    }
}

@include media-tablet-portrait {
    .rectangles {
        left: 20px;
        right: -70px;
    }
}

@include media-mobile {
    .rectangles {
        bottom: -50px;
        left: 0;
    }

    .rectangle {
        height: 36px;
        border-radius: 12px;

        &:first-of-type,
        &:last-of-type {
            display: none;
        }

        &.rectangle--highlighted {
            outline: 3px solid rgba(31, 122, 102, 0.1);
            outline-offset: 0;
            height: 68px;
            padding: 12px;

            .image {
                margin-left: 0;

                img {
                    height: 44px !important;
                    width: 44px !important;
                }
            }
        }
    }

    .manager-name {
        font-size: 14px;
    }

    .manager-description {
        font-size: 14px;
    }
}
