@import "~@ui/styles/tools";

.block {
    display: flex;
    flex-direction: column;
    gap: 36px;

    &-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        gap: 20px;

        @include media-tablet {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &--text {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
}

.heading {
    font-size: 24px;
    line-height: 32px;
    color: #1E2021;
}

.results {
    font-size: 20px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
        font-weight: 500;

        &--passed {
            color: #20836D;
        }
    }

    .subtitle {
        font-weight: 400;
    }

    .hint {
        font-weight: 400;
        color: rgba(30, 32, 33, 0.5);
    }
}

.mark-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: end;
    padding: 16px 12px;
    border-radius: 16px;
    border: 1px solid rgba(30, 32, 33, 0.1);

    .mark {
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;

        margin-right: 12px;

        &--passed {
            color: #20836D;
        }
    }

    .description {
        font-size: 16px;
        line-height: 1.4;
        color: rgba(30, 32, 33, 1);
    }

    .comment {
        font-size: 16px;
        line-height: 1.4;
        color: rgba(30, 32, 33, 0.5);
        margin-left: auto;
    }
}

.achievement {
    display: flex;
    flex-direction: row;
    padding: 20px;

    border: 1px solid rgba(30, 32, 33, 0.1);
    border-radius: 12px;

    align-items: center;
    gap: 20px;

    min-width: 255px;
    max-height: 150px;

    &-image {
        width: 74px;
        height: 74px;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    &-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &--name {
        font-size: 20px;
        line-height: 1.4;
        color: #1E2021;
        font-weight: 400;
    }

    &--stat {
        font-size: 14px;
        line-height: 1.6;
        color: #A2A8AD;
        font-weight: 400;
    }
}