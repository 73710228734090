@import '~@ui/styles/tools';

@import 'src/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, max-content);

    grid-template-areas:
        'revenue revenue'
        'withdraw product';
}

.title {
    font-size: 48px;
    font-weight: 600;
    max-width: 25ch;
    margin-bottom: 32px;
}

.revenue {
    margin-bottom: 20px;
    grid-area: revenue;
}

.withdraw {
    grid-area: withdraw;
    margin-right: 10px;
}

.product {
    grid-area: product;
    margin-left: 10px;
}

@include medium-breakpoint {
    .title {
        font-size: 40px;
    }
}

@include small-breakpoint {
    .features {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, max-content);

        grid-template-areas:
            'revenue'
            'withdraw'
            'product';

        // grid-template-columns: repeat(3, 1fr);
        // grid-template-rows: repeat(1, 1fr);
        // grid-template-areas: 'revenue withdraw product';
    }

    .title {
        font-size: 32px;
    }

    .revenue {
        // margin-bottom: 20px;
        // margin-bottom: 0;
        // margin-right: 20px;
    }

    .withdraw {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .product {
        margin-left: 0;
    }
}

@include media-tablet {
    .title {
        font-size: 24px;
    }

    .features {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: max-content 1fr;
        grid-template-areas:
            'revenue revenue'
            'withdraw product';
    }

    .revenue {
        margin-bottom: 12px;
        margin-right: 0;
    }

    .withdraw {
        margin-bottom: 0;
        margin-right: 12px;
    }

    .product {
        margin-left: 0;
    }
}

@include media-tablet-portrait {
    .features {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, max-content);

        grid-template-areas:
            'revenue'
            'withdraw'
            'product';
    }
    .withdraw {
        margin-bottom: 12px;
        margin-right: 0;
    }
}

@include media-mobile {
    .features {
        grid-template-rows: repeat(3, max-content);
    }

    .title {
        font-size: 26px;
        max-width: 100%;
        width: 20ch;
    }
}
