@import '~@ui/styles/tools';
@import 'src/styles/variables';

.card {
    background: $color-page-background;
    border-radius: 12px;
    margin: 0 10px;
    height: 100%;
}

@include media-tablet {
    .card {
        // width: 80%;
        margin: 0 6px;
    }
}
