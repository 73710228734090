@import "~@ui/styles/tools";

.content {
    color: $color-title-active;

    h1, h2, h3 {
        margin-bottom: 32px;
    }

    h1 {
        font-size: 48px;
        font-weight: 600;
        line-height: 50px;
        letter-spacing: 1px;
    }

    h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.4;
    }

    h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.4;
    }

    p {
        font-size: 18px;
        line-height: 1.4;
        font-weight: 400;
    }

    ul, ol {
        margin: 0 0 32px;
        padding-left: 32px;

        & > li {
            font-size: 18px;
            line-height: 1.4;
            font-weight: 400;
            margin: 0 0 4px;

            & > ul, & > ol {
                margin: 0 0 4px;
                padding-left: 18px;
            }
        }
    }

    blockquote {
        text-align: center;
        margin-bottom: 32px;

        img {
            margin-bottom: 28px;
        }

        p {
            font-style: italic;
        }

        footer {
            display: flex;
            flex-direction: column;
            gap: 4px;

            span[data-role="position"] {
                color: $color-grey--dark;
            }
        }
    }

    div[data-role="jetlend/block"] {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 28px;
        text-align: center;
        margin-bottom: 32px;

        p {
            margin: 0;
        }
    }

    div[data-role="jetlend/spoiler"] {
        margin-bottom: 32px;

        p {
            margin: 0;
            color: inherit;
            font-size: inherit;
        }
    }

    div[data-role="table"] {
        overflow: auto;
        margin-bottom: 32px;
        table {
            border: 1px solid $color-input-background;
            border-collapse: separate;
            border-spacing: 0;
            border-radius: 12px;
            overflow: hidden;

            tr {
                background-color: $color-off-white;
            }

            th, td {
                border: 1px solid $color-input-background;
                padding: 12px;
                font-weight: 400;
            }

            thead {
                th, td {
                    color: #A1A1AA;
                }
            }

            tbody {
                tr:nth-child(odd) {
                    background-color: #FAFAFA;

                }

                td:first-child {
                    font-weight: 500;
                }
            }
        }
    }

    :global(.wp-caption) {
        width: auto !important;
    }

    @include media-tablet {
        p {
            font-size: 16px;
            line-height: 1.2;
        }
    }

    @include media-mobile {
        h2 {
            font-size: 18px;
            line-height: 24px;
        }

        h3 {
            font-size: 16px;
            line-height: 22px;
        }

        p {
            font-size: 16px;
            line-height: 22px;
        }
    }
}

.image-wrapper {
    display: grid;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.embed-content {
    width: 100%;
    height: unset;
}