@import '~@ui/styles/tools';

@import 'src/styles/variables';
@import '../mixins.scss';

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.content {
    --section-padding: 32px;
    --content-padding: 18px;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content 1fr;
    grid-template-areas:
        'heading'
        'description'
        'app';

    padding: var(--content-padding);

    height: 100%;
}

.title-wrapper {
    grid-area: heading;
    margin-bottom: 16px;
}

.title {
    @include feature-heading();
}

.description-wrapper {
    grid-area: description;
}

.description {
    @include feature-description();
    max-width: 25ch;
}

.image-wrapper {
    grid-area: app;
    height: 100%;
    width: 100%;
    position: relative;

    min-height: 453px;
}

.image {
    min-height: 453px;
    position: absolute;
    left: 50px;
    right: 50px;
    top: 0;
    bottom: -50px;
    pointer-events: none;

    img {
        width: unset !important;
        left: 50% !important;
        transform: translateX(-50%);
        pointer-events: none;
    }
}

@include small-breakpoint {
    .content {
        grid-template-columns: max-content 1fr;
        grid-template-rows: min-content 1fr;
        grid-template-areas:
            'heading app'
            'description app';
    }

    .title {
        max-width: 15ch;
    }

    .image-wrapper {
        margin-top: auto;
        width: 100%;
        min-height: 236px;
        max-height: 236px;
    }

    .image {
        position: relative;
        min-height: unset;
        height: 100%;
        left: unset;
        right: 0;
        width: auto;

        img {
            height: calc(100% + 32px * 2 + 18px * 2) !important;
            top: -50px !important;
            bottom: -50px !important;
            left: calc(100% + 18px) !important;
            width: auto !important;
            transform: translateX(-100%);
        }
    }
}

@include media-tablet {
    .content {
        --section-padding: 20px;
        --container-padding: 16px;

        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content 1fr;
        grid-template-areas:
            'heading'
            'description'
            'app';

        padding: 0;
    }

    .image-wrapper {
        min-height: 250px;
        max-height: 250px;
    }

    .image {
        min-height: unset;

        img {
            height: calc(100% + var(--section-padding, 20px) * 2) !important;
            top: calc(-1 * var(--section-padding, 20px)) !important;
            bottom: calc(-1 * var(--section-padding, 20px)) !important;
            // left: calc(100% + 2 * var(--container-padding, 16px)) !important;
            left: 50% !important;
            transform: translateX(-50%);
        }
    }
}

@include media-tablet-portrait {
    .content {
        grid-template-columns: max-content 1fr;
        grid-template-rows: min-content 1fr;
        grid-template-areas:
            'heading app'
            'description app';
    }

    .image-wrapper {
        min-height: 200px;
        max-height: 200px;
    }

    .image {
        min-height: unset;

        img {
            height: calc(100% + var(--section-padding, 20px) * 2) !important;
            top: calc(-1 * var(--section-padding, 20px)) !important;
            bottom: calc(-1 * var(--section-padding, 20px)) !important;
            left: calc(100% + 2 * var(--container-padding, 16px)) !important;
            transform: translateX(-100%);
        }
    }
}

@include media-mobile {
    .content {
        grid-template-columns: min(15ch, max-content) 1fr;
        grid-template-rows: max-content 1fr;
        grid-template-areas:
            'heading app'
            'description app';
        // 'app';
    }

    .image-wrapper {
        min-height: unset;
        // max-height: 200px;
        height: 200px;

        margin-top: auto;
    }

    .image {
        img {
            left: 50% !important;
            transform: translateX(-50%);
        }
    }
}

@media only screen and (max-width: 450px) {
    .content {
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content 1fr;
        grid-template-areas:
            'heading'
            'description'
            'app';
    }
}
