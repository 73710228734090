@import '~@ui/styles/tools';

.card {
    padding: 32px;
    border-radius: 24px;
    background-color: #ffffff;
    display: grid;
    overflow: hidden;

    grid-template-rows: max-content max-content;
    grid-template-columns: 1fr max-content;
    grid-template-areas:
        'title image'
        'description image';

    &.card--long {
        grid-column: span 2;

        grid-template-rows: max-content 1fr;
        grid-template-columns: max-content 1fr;
        grid-template-areas:
            'title image'
            'description image';
    }
}

.title-container {
    grid-area: title;
    margin-bottom: 16px;
}

.description-container {
    grid-area: description;
}

.image-container {
    grid-area: image;
    margin-top: auto;
    position: relative;

    display: flex;

    height: 100%;
    width: 100%;
}

.title {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    max-width: 11ch;
}

.description {
    color: #8c8c8c;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 25ch;

    margin: 0;
    padding: 0;
}

.image {
    margin-bottom: auto;
}

@include media-tablet {
    .card {
        height: auto;
        padding: 20px;

        grid-template-rows: max-content max-content;
        grid-template-columns: max-content 1fr;
        grid-template-areas:
            'title image'
            'description image';

        &.card--long {
            grid-column: span 1;

            grid-template-rows: max-content 1fr;
            grid-template-columns: max-content 1fr;
            grid-template-areas:
                'title image'
                'description image';

            .image-container {
                height: 150px;

                margin-left: auto;
                width: min(100%, 700px);
                // width: min(500px, 70%);
            }
        }
    }

    .title {
        font-size: 20px;
        max-width: unset;
    }

    .description {
        font-size: 16px;
    }

    .image {
        margin-left: auto;
        margin-top: unset;

        img {
            height: 56px !important;
            width: 56px !important;
        }
    }
}

@include media-tablet-portrait {
    .card {
        &.card--long {
            .image-container {
                width: 100%;
            }
        }
    }
}

@include media-mobile {
    .feature-title {
        font-size: 32px;
        max-width: 100%;
        width: 100%;
    }

    .title {
        max-width: 11ch;
    }

    .description-container {
        margin-bottom: 10px;
    }

    .card {
        &.card--long {
            grid-template-rows: max-content max-content 1fr;
            grid-template-columns: 1fr;
            grid-template-areas:
                'title'
                'description'
                'image';
        }

        &:not(.card--long) .description {
            max-width: 18ch;
        }
    }
}
