@import '~@ui/styles/tools';

.container {
    position: relative;
    overflow-x: hidden;

    &:hover {
        .arrows {
            opacity: 1;
        }
    }
}

.item-wrapper {
    margin: 0 20px;
}

.slider {
    > div {
        display: flex;

        &:hover {
            cursor: pointer;
        }
    }
}

@include media-tablet {
    .container {
        // Waiting for the new design
        // margin: 0 -9% 0 0;
        & > .slim {
            // Waiting for the new design
            // width: max(80%, 282px);

            // Needed for centering indicators: 100% - width = margin-left
            & > div > ul {
                margin-left: 20%;
            }
        }
    }
}

@media only screen and (max-width: 600px){
    .container {
        & > .slim {
            width: max(80%, 282px);
        }
    }
}
