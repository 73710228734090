@import '~@ui/styles/tools';
@import 'src/styles/variables';

$color-description: #64696c;

@mixin feature-heading {
    color: #1c2723;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;

    @include media-tablet {
        font-size: 20px;
    }
}

@mixin feature-value {
    color: #1f7a66;
    font-size: 76px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    @include media-mobile {
        font-size: 46px;
    }
}

@mixin feature-description {
    font-size: 20px;
    line-height: normal;
    color: $color-text-light;
    margin-bottom: 0;

    @include media-mobile {
        font-size: 16px;
    }
}
