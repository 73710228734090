@import '~@ui/styles/tools';

.withdraw-example {
    padding: 12.5px 16px;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    // display: flex;
    align-items: center;
    // justify-content: flex-start;

    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 12.105px 0px rgba(120, 120, 120, 0.08);
}

.logo {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #e2e3e4;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    // flex: 1;
    padding-left: 8px;
}

.text-info {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.title {
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.363px;
}

.description {
    color: #1f7a66;
    font-size: 14.526px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.363px;
}

.amount {
    color: #1f7a66;
    text-align: right;
    font-size: 15.737px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

@include media-tablet {
    .withdraw-example {
        padding: 7.5px 9px;
    }

    .logo {
        width: 21px;
        height: 21px;
        padding-left: 5px;

        img {
            width: 9px !important;
            height: 9px !important;
        }
    }

    .text-info {
        margin: 0 5px;
    }

    .title {
        font-size: 11.5px;
        letter-spacing: 0.216px;
    }

    .description {
        font-size: 8.5px;
        letter-spacing: 0.216px;
    }

    .amount {
        font-size: 9.5px;
    }
}
