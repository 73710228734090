@import '~@ui/styles/tools';
@import 'src/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.feature-wrapper {
    display: block;
}

.feature-info {
    grid-area: info;
}

.feature-title {
    font-size: 48px;
    font-weight: 600;
    max-width: 50%;
    margin-bottom: 20px;
}

.feature-description {
    margin-bottom: 48px;
    max-width: 30ch;

    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.3px;
}

.feature-content {
    grid-area: content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, max-content);
}

.card {
    &:nth-of-type(1) {
        margin-right: 16px;
        margin-bottom: 16px;
    }
    &:nth-of-type(2) {
        margin-left: 16px;
        margin-bottom: 16px;
    }
    &:nth-of-type(3) {
        margin-top: 16px;
    }
}

@include medium-breakpoint {
    .feature-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content;
        grid-template-areas: 'info' 'content';
    }

    .feature-title {
        margin-bottom: 20px;
        font-size: 40px;
    }

    .feature-description {
        margin-bottom: 32px;
        max-width: 30ch;
    }
}

@include small-breakpoint {
    .feature-title {
        font-size: 32px;
    }

    .card {
        &:nth-of-type(3) {
            min-height: 336px;
        }
    }
}

@include media-tablet {
    .feature-title {
        font-size: 24px;
    }

    .feature-description {
        margin-bottom: 28px;
    }

    .feature-content {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: min-content max-content;
    }

    .card {
        &:nth-of-type(1) {
            margin-right: 6px;
            margin-bottom: 12px;
        }

        &:nth-of-type(2) {
            margin-left: 6px;
            margin-bottom: 12px;
        }

        &:nth-of-type(3) {
            margin-top: 0;
            min-height: unset;

            grid-column: 1 / -1;
        }
    }
}

@include media-tablet-portrait {
    .feature-content {
        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content max-content;
    }
    .card {
        &:nth-of-type(1) {
            margin-right: 0;
        }

        &:nth-of-type(2) {
            margin-left: 0;
        }
    }
}

@include media-mobile {
    .feature-title {
        font-size: 26px;
        max-width: 100%;
        width: 100%;
        max-width: 10ch;
    }

    // .card {
    //     &:nth-of-type(3) {
    //         min-height: unset;
    //     }
    // }
}
