@import '~@ui/styles/tools';
@import '../mixins.scss';

.content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content 1fr;
    grid-template-areas:
        'heading'
        'description'
        'example';

    padding: 18px;
}

.title-wrapper {
    grid-area: heading;
    margin-bottom: 16px;
}

.title {
    @include feature-heading();
}

.description-wrapper {
    grid-area: description;
    margin-bottom: 54px;
}

.description {
    @include feature-description();

    max-width: 35ch;
}

.example-wrapper {
    grid-area: example;
}

.example-title {
    font-size: 29px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    margin-bottom: 16px;
}

.example-transaction:not(:last-child) {
    margin-bottom: 12px;
}

.example-transaction:last-child {
    box-shadow: 0px 0px 16px 0px rgba(120, 120, 120, 0.08);
}

.example-text {
    color: #a2a8ad;
    font-size: 19.368px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.363px;
    margin: 0;
    padding: 0;
    padding-bottom: 12px;
}

@include media-tablet {
    .content {
        padding: 0;
    }

    .description-wrapper {
        margin-bottom: 12px;
    }

    .description {
        font-size: 16px;
    }

    .example-title {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .example-transaction:not(:last-child) {
        margin-bottom: 7px;
    }

    .example-text {
        font-size: 10px;
        padding-bottom: 7px;
    }
}

@include media-mobile {
    .description {
        max-width: 25ch;
    }
}
