@import '~@ui/styles/tools';
@import 'src/styles/variables';
@import '../mixins.scss';
$color-description: #64696c;

.content {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content max-content 1fr;
    grid-template-areas:
        'heading value'
        'description value-description'
        'chart chart';

    padding: 18px;
}

.title-wrapper {
    grid-area: heading;
}

.description-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-area: description;
}

.value-wrapper {
    grid-area: value;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.title {
    @include feature-heading();
    max-width: 25ch;
}

.description {
    @include feature-description();

    &.description--top {
        max-width: 35ch;
        text-align: left;
    }
    &.description--bottom {
        max-width: 22ch;
        text-align: right;
    }
}

.revenue-value-description {
    grid-area: value-description;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.revenue-value {
    @include feature-value();

    margin-bottom: 6px;
}

.chart-wrapper {
    grid-area: chart;
    margin-top: 83px;
    flex: 1;
}

.chart {
    --chart-height: 353px;
    min-height: var(--chart-height);
    height: var(--chart-height);
}

@include media-tablet {
    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        height: 100%;
    }

    .title-wrapper {
        margin-bottom: 16px;
    }

    .description--top,
    .description--bottom {
        font-size: 16px;
    }

    .value-wrapper {
        margin-top: auto;
    }

    .revenue-value {
        font-size: 40px;
    }

    .chart-wrapper {
        display: none;
    }
}

@include media-mobile {
    .description.description--top {
        max-width: 30ch;
    }
}
