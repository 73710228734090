@import "@frontend/jetlend-web-ui/src/styles/tools";

.container {
    background: #fff;
    border-radius: $border-radius;
    overflow: hidden;
    height: fit-content;
    border: 1px solid #EBEEEF;
}

.container--shadow {
    box-shadow: 1px 4px 9px 0 #00000018;
}

.button {
    display: flex;
    background: #fff;
    color: #000;
    border: none;
    text-align: left;
    padding: 16px 12px;
    margin: 0;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;

    &:hover {
        background: #F0F0F0;
    }
}

.button__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: $color-title-active;
    flex: 1;
    transition: all ease-in-out 400ms;
}

.button__title--active {

}

.button__icon {
    transition: all ease-in-out 400ms;
}

.button__icon--open {
    transform: rotate(-180deg);
}

.button__icon--disabled {
    visibility: hidden;
}

.content {
    padding: 16px 12px;
    font-size: 16px;
    color: #A2A8AD;
    line-height: 18px;
}